#Menu {
    width: 24.5%;/*376px; 320px*/
    min-width: 260px;
    background-color: #35605A;
    /* background: black; */
    font-family: Arial, Helvetica, sans-serif;/* "Arial Black", Gadget, sans-serif ;Impact, Charcoal, sans-serif; */
    color: #ffffff;
    
    transition: none;
     box-shadow: 5px 7px 10px 0 rgba(0, 0, 0, 0.16);
     color: #ffffff;
}
.menuCompleto {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    width: 100%;
    height: 100%;
    /* min-width: 1000px; */
    /* background-color: rgb(245, 246, 250); */
}
.menuDesplegable {
    display: flex;
    flex-direction: column;
    flex-basis: 85%;
    max-height: 85%;
    overflow: auto;
}

.menuDesplegable::-webkit-scrollbar-track {
    border: 1px solid #000;
    padding: 2px 0;
    background-color: #1D4A44;
  }
  
  .menuDesplegable::-webkit-scrollbar {
    width: 10px;
  }
  
  .menuDesplegable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #05857e;
    border: 1px solid #000;
  }
.menuFijo {
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    align-items: flex-end;
    flex-basis: 15%;
    height: 15%;
    font-size: 20px;
    /* min-width: 480px; */
}

.vh-height-overflow {
    overflow: auto;
    height: calc(100vh - 70px);
}

.visible{
    display:block;
}

.no-visible{
    display:none;
}

.menu__nombrePadre{
    color: white;
    text-decoration: none;
    border-style: solid;
    border-bottom-width: 0.1px;
    border-bottom-style: outset;
    border-color: transparent transparent rgba(255, 255, 255, 0.486)   transparent;
    
}
.menu__nombrePadre a{
    color: #ffffff;
    text-decoration: none;
    /* font-size: 20px; */
}
.menu__nombrePadre  :hover{
    background-color: #01A59C;
    color: #ffffff;
    padding: 0.5px;
}

.menu__nombreIcon{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.menu__nombreIcon a{
    color: #ffffff;
    text-decoration: none;
    font-size: 20px;
}
.IconAlmacen {
    width: 25px;
    height: 25px;
    margin-right: 4px;
}
.IconDashboard {
    width: 25px;
    height: 25px;
    margin-right: 4px;
}
.IconAdministracion {
    width: 27px;
    height: 27px;
    margin-right: 4px;
}
.IconVentas {
    width: 27px;
    height: 27px;
    margin-right: 4px;
}
/* .panel-title > a:before {
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f0d8";
}
.panel-title > a.collapsed:before {
    content:"\f0d7";
}
.panel-title > a:hover, 
.panel-title > a:active, 
.panel-title > a:focus  {
    text-decoration:none;
} */
.menu__nombrePadre div:after {
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    content: "\f0d8";
}
.menu__nombrePadre div.collapsed:after {
    content:"\f0d7";
}

.menu__nombre{
    color: white;
    text-decoration: none;
    /* border-style: solid;
    border-color: transparent transparent rgba(190, 190, 190, 0.308)   transparent; */
    
}
.menu__nombre a{
    color: #ffffff;
    text-decoration: none;
}
.menu__nombre  :hover{
    background-color: #01A59C;
    color: #ffffff;
    padding: 0.5px;
}

/* Sub Menus */

.subMenus__container{
    background-color: #1D4A44;
    color: white;
    text-decoration: none;
}
/* sub menu venta rapida */
.subSubMenus__container{
    background-color: #0B3933;
    color: white;
    text-decoration: none;
}
