.dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: center; */
  height: 100%;
  margin-top: 40px;
  /* min-width: 1000px; */
}

.dashboardBotons {
  flex-basis: 25%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.dashboardBotonsEncabezado {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 3px;
  margin-left: 5px;
}

.dashboardGraphicsBotons {
  flex-basis: 75%;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* height: 100%; */
}

.dashboard__graphics {
  flex-basis: 100%;
  display: flex;
  align-items: center;

  justify-content: center;
  width: 100%;
  margin-top: 0px;
  flex-direction: column;
  /* margin-bottom: 10%; */
} 

.dashboard__graphicsDiaMes {
  flex-basis: 100%;  
  /* flex-wrap: wrap; */
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  flex-direction: row;
}

.dashboard__graphicsDia {
  flex-basis: 40%;
  display: flex;
  justify-content: center;
  /* width: 100%; */
  flex-direction: column;
}

.cardGraphics {
  display: flex;
  /* flex-basis: 50%; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
    border-style: solid;
    border-width: 0.5px 0.5px 0.5px 0.5px;
    border-color:  rgb(165, 165, 165) rgb(165, 165, 165)  rgb(165, 165, 165)  ; 
    border-radius: 30px;
    -webkit-box-shadow: -15px 18px 18px -6px rgba(0,0,0,0.54);
    -moz-box-shadow: -15px 18px 18px -6px rgba(0,0,0,0.54);
    box-shadow: -15px 15px 18px -6px rgba(0,0,0,0.54);
}

.dashboard__content {
  flex-basis: 20%;
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  padding-right: 5%;
  padding-left: 5%;
  flex-wrap: wrap;
  margin-bottom: 10%;
}

.dashboard__content--item-d {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: #034b2f;
  margin: 20px;
  color: white;
  padding: 10px;
  transition: box-shadow 0.4s;
  min-height: 100px;
  max-width: 280px;
}

.dashboard__content--item-d span {
  flex-grow: 2;
  color: white;
  font-size: xx-large;
  font-weight: bold;
  align-self: flex-end;
}
.dashboard__content--item-d:hover {
  box-shadow: 5px 5px #034b2f;
  border-radius: 5px;
}
.dashboard__content--item-d div {
  display: flex;
  flex-grow: 1;
  color: white;
  align-items: center;
}

.dashboard__content--item-d i {
  color: white;
  font-size: xx-large;
  margin-right: 20px;
}

.dashboard__content--item-g {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #01a59c;
  margin: 20px;
  color: white;
  padding: 10px;
  min-height: 100px;
  max-width: 280px;

  transition: box-shadow 0.4s;
}

.dashboard__content--item-g:hover {
  box-shadow: 5px 5px #01a59c;
  border-radius: 5px;
}

.dashboard__content--item-g > span {
  color: white;
  font-size: xx-large;
  font-weight: bold;
  align-self: flex-end;
}
.dashboard__content--item-g div {
  display: flex;
  flex-grow: 1;
  color: white;

  align-items: center;
}

.dashboard__content--item-g i {
  color: white;
  font-size: xx-large;
  margin-right: 20px;
}

.dashboard__footer {
  display: flex;
  margin-top: 0px;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding: 5px;
}

@media (max-width: 1357px) {
  .dashboard__graphics {
    flex-basis: none;
    
  }
  .dashboardBotons {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    height: 50%;
    min-width: 680px;
  }

  .dashboardBotonsEncabezado {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 3px;
    margin-left: 5px;
  }
}

@media (max-width: 1679px){

  .dashboard__graphicsDiaMes {
    flex-basis: 100%;  
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    flex-direction: row;
  }



}