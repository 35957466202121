
.preventa__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
}

.preventa__container .preventa {

    display: flex;
    /* justify-content: space-between; */
    width: 100%;

    flex-grow: 1;
}


.preventa__container .preventa__container_data {
    flex-basis: 47%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    margin-left: 3%;
}

.preventa__container .preventa__container_data2 {
    flex-basis: 47%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    width: 50%;
    height: 100%;
    margin-left: 3%;
}

.preventa__container .preventa__cliente {
    margin-bottom: 10px;
    flex-basis: 10%;
    display: flex;
    align-items: center;
}

.preventa__container .preventa__cliente2 {
    margin-top: 25px;
    margin-left: 5%;
    margin-bottom: 20px;
    font-size: 25px;
}

.preventa__container .preventa__cliente--contenido {
    display: flex;
    flex-flow: column;
}

.preventa__container .preventa__carrito {
    height: 290px;
    overflow: auto;
    /* border: solid 0.5px #464646;
    border-radius: 40px; */
}

.preventa__container .itemTotal {
    padding-right: 20px;
    background-color: #034b2f;
    width: 130px;
    color: white;
    text-align: right;
    height: 32px;
}

.preventa__container .encabezado {
    display: flex;
    flex-flow: column;
    justify-content: start;
    flex-basis: 40%;
    margin-top: 15px;
    margin-bottom: 10px;
    margin-left: 3%;
    margin-right: 20px;
}

.preventa__container .encabezado2 {
    /*display: flex;*/
    /*flex-flow: column;*/
    justify-content: start;
}

.preventa__container .preventa__tipo--comprobante {
    display: flex;
    margin-bottom: 10px;
}

.preventa__tipo--comprobante {
    display: flex;
    margin-bottom: 10px;
}

.preventa__container .preventa__tipo--comprobante--item {
    background-color: #034b2f;
    flex-grow: 1;
    text-align: center;
    border: none;
    height: 60px;
    margin-top: 5px;
    color: white;

    transition: flex 0.5s;
}

.preventa__tipo--comprobante--item2 {
    background-color: #034b2f;
    flex-grow: 1;
    text-align: center;
    border: none;
    height: 60px;
    margin-top: 5px;
    color: white;
    transition: flex 0.5s;
}

.preventa__container .preventa__tipo--comprobante--item:hover {
    background-color: #01a59c;
    flex-grow: 1.2;
    transition: flex 0.5s;
    color: white;
    cursor: pointer;
}

.preventa__tipo--comprobante--item2:hover {
    background-color: #01a59c;
    flex-grow: 1.2;
    transition: flex 0.5s;
    color: white;
    cursor: pointer;
}


.preventa__container .preventa__tipo--comprobante--item--active {
    background-color: #01a59c;
    flex-grow: 1.2;
    color: white;
    cursor: pointer;
    text-align: center;
    border: none;
    height: 60px;
    margin-top: 5px;
}

.preventa__tipo--comprobante--item--active2 {
    background-color: #01a59c;
    flex-grow: 1.2;
    color: white;
    cursor: pointer;
    text-align: center;
    border: none;
    height: 60px;
    margin-top: 5px;
}

.preventa__container .info__input__container {
    display: flex;
    flex-flow: column;
    width: 100%;
    bottom: 25%;
    /* position:absolute; */
}

.preventa__container .totals__container {
    display: flex;
    margin-top: 10px;
    background-color: #b2c3c0;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    flex-shrink: 0;
    padding-left: 10px;
    /* position:absolute; */
    /* bottom:0; */
}

.preventa__container .totals__container--item {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-basis: 30%;
}

.preventa__container .totals__container--item p {
    margin-right: 20px;
    margin-bottom: 0;
}

.preventa__container .totals__container--item-total {
    background-color: #01a59c;
    align-items: center;
    display: flex;
}

.preventa__container .totals__container--item-total span {
    flex-basis: 40%;
    text-align: center;
    font-weight: bold;
    outline: none;
    color: white;
    border: none;
    background-color: transparent;
    font-size: 3vw;
    box-shadow: 0 18px 20px 0 rgba(1, 165, 156, 0.57);
    padding-left: 30px;
    padding-right: 30px;
}

.preventa__container .generar__boton {
    height: 10vh;
    padding: 0em 1em;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    box-shadow: 0 12px 24px 0 rgba(3, 75, 47, 0.6);
    background-color: #034b2f;
    font-size: 3vw;
}

.preventa__container .cancelar__boton {
    height: 10vh;
    padding: 0em 1em;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    box-shadow: 0 12px 24px 0 rgba(167, 1, 1, 0.6);
    background-color: #700101;
    font-size: 3vw;
}

.preventa__container .preventa__carrito {
    height: 290px;
    overflow: auto;
    /* border: solid 0.5px #464646;
    border-radius: 40px; */
}

.preventa__container .toggle-inner {
    background-color: #01a59c;
}

.preventa__container .PreVBuscarProducto {
    display: flex;
    justify-content: space-around;
    padding-bottom: 5%;
}

.preventa__container .trDetallePreventa {
    display: flex;

}

.preventa__container .trDetallePreventa:hover div {

    opacity: 100;
}

.cabeceraCarrito {
    font-size: 20px;
    display: flex;
    justify-content: space-between;
}

.preventa__container .tdDivDetallePreventa, .tdDivDetallePreventaPrecios {
    /* display: none; */
    opacity: 0;
}

.preventa__container .tdDivDetallePreventa {
    display: flex;
}

.preventa__container .tdDetallePreventa {
    display: flex;
}

.preventa__container .itemNombre {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.preventa__container .inputDet {
    width: 3%;
    min-width: 4em;
    flex-grow: 1;
}

#tableProdPreventa {
    overflow: auto;
    min-height: 100%; /*alto*/
}

#tableProdPreventa th {
    position: sticky;
    top: 0;
    background: white;
    border: 0px;
    z-index: 1;
}

.inputCantidadDet, .inputPrecioVentaDet {
    padding: 4px 0px 4px 4px;
    border-radius: 0px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;

    text-align: right;
    width: 100%;
}

.DocClientePreventa {
    padding: 4px 4px 4px 12px;
    width: 120px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    outline: none;
}

.DocClientePreventa:focus {
    border-bottom: 2px solid #464646;
}

.inputAlias {
    padding: 4px 4px 4px 12px;
    /* width: 250px; */
    flex-basis: 30%;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    outline: none;
}

.inputAlias:focus {
    border-bottom: 2px solid #464646;
}

#tableDetProdPreventa {
    margin-left: 10px;
    overflow: auto;
    min-height: 80%; /*alto*/
    border-radius: 5px;
    max-width: 702px;
}

#tableDetProdPreventa th {
    position: sticky;
    top: 0;
    background: white;
    border: 0px;
    z-index: 1; /*para q se ponga delante*/
}

.encabezadoPV {
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex-basis: 50%;
    margin-bottom: 10px;
}

.preventa__tipo--comprobante {
    display: flex;
    margin-bottom: 10px;
}

.preventa__tipo--comprobante--item {
    background-color: #D7D6D6;
    flex-grow: 1;
    text-align: center;
    border: none;
    height: 45px;
    margin-top: 5px;
}

.preventa__tipo--comprobante--item:hover {
    background-color: #007bff;
    flex-grow: 3;
    transition: flex .5s;
    color: white;
    cursor: pointer;
}

.preventa__tipo--comprobante--item--active2 {
    background-color: #01a59c;
    color: white;
    flex-grow: 3;
    color: white;
    transition: flex .5s;
    cursor: pointer;
    text-align: center;
    border: none;
    /*height: 45px;*/
    margin-top: 5px;
}

.preventa__container .lista_productos {

    height: 100px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    overflow: auto;
}

.preventa__container .lista_productos__item {
    flex-grow: 1;
    margin-bottom: 5px;
}

/***************************
**PARA EL TECLADO NUMERICO**
****************************/
/* body,html{margin:0;padding:0} */
.simple-keyboard {
    font-family: HelveticaNeue-Light,
    Helvetica Neue Light,
    Helvetica Neue,
    Helvetica, Arial, Lucida Grande, sans-serif;
    width: 250px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    touch-action: manipulation
}

.simple-keyboard .hg-row {
    display: flex
}

.simple-keyboard .hg-row:not(:last-child) {
    margin-bottom: 5px
}

.simple-keyboard .hg-row .hg-button-container,
.simple-keyboard .hg-row
.hg-button:not(:last-child) {
    margin-right: 5px
}

.simple-keyboard .hg-row > div:last-child {
    margin-right: 0
}

.simple-keyboard .hg-row .hg-button-container {
    display: flex
}

.simple-keyboard .hg-button {
    display: inline-block;
    flex-grow: 1;
    cursor: pointer
}

.simple-keyboard .hg-button span {
    pointer-events: none
}

.simple-keyboard.hg-theme-default {
    background-color: rgba(0, 0, 0, .1);
    padding: 5px;
    border-radius: 5px
}

.simple-keyboard.hg-theme-default
.hg-button {
    box-shadow: 0 0 3px -1px rgba(0, 0, 0, .3);
    height: 50px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 5px;
    background: #2E60FA;
    border-bottom: 1px solid #4c66fa;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center
}

.simple-keyboard button.hg-button {
    border-width: 0;
    outline: 0;
    font-size: inherit
}

.simple-keyboard.hg-theme-default.hg-layout-numeric
.hg-button {
    width: 33.3%;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center
}

.simple-keyboard.hg-theme-default .hg-button.hg-button-numpadadd, .simple-keyboard.hg-theme-default
.hg-button.hg-button-numpadenter {
    height: 85px
}

.simple-keyboard.hg-theme-default
.hg-button.hg-button-numpad0 {
    width: 105px
}

.simple-keyboard.hg-theme-default
.hg-button.hg-button-com {
    max-width: 85px
}

.simple-keyboard.hg-theme-default
.hg-button.hg-standardBtn.hg-button-at {
    max-width: 45px
}

.simple-keyboard.hg-theme-default
.hg-button.hg-selectedButton {
    background: rgba(5, 25, 70, .53);
    color: #fff
}

.simple-keyboard.hg-theme-default .hg-button.hg-standardBtn[data-skbtn=".com"] {
    max-width: 82px
}

.simple-keyboard.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
    max-width: 60px
}

/***************************
**PARA EL TECLADO NUMERICO**
************FIN*************
****************************/
.checkbox-togglePreventa {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin: 0;
    padding: 4px;
}

.checkbox-togglePreventa .togglePreventa {
    isolation: isolate;
    position: relative;
    height: 16px;
    width: 34px;
    border-radius: 15px;
    background: #b7b7b7;
    overflow: hidden;
}

.checkbox-togglePreventa .togglePreventa-inner {
    z-index: 2;
    position: absolute;
    top: 1px;
    left: 1px;
    height: 14px;
    width: 32px;
    border-radius: 15px;
    overflow: hidden;
}

.checkbox-togglePreventa input:disabled ~ .togglePreventa-inner {
    background: #eaeaea;
}

.checkbox-togglePreventa .active-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200%;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.05s linear 0.17s;
}

.checkbox-togglePreventa .toggle-state {
    display: none;
}

.checkbox-togglePreventa .indicator {
    height: 100%;
    width: 200%;
    background: white;
    border-radius: 13px;
    transform: translate3d(-75%, 0, 0);
    transition: transform 0.2s cubic-bezier(0.85, 0.05, 0.18, 1.35);
}

.checkbox-togglePreventa .toggle-state:checked ~ .active-bg {
    transform: translate3d(-50%, 0, 0);
}

.checkbox-togglePreventa .toggle-state:checked ~ .togglePreventa-inner .indicator {
    transform: translate3d(25%, 0, 0);
}

.checkbox-togglePreventa .toggle-state:checked:enabled ~ .active-bg {
    background: #01A59C
}
